<template>
  <div class="div-paddings">
    <b-container class="pt-5 pt-lg-5 wrapper pb-5">
      <div
        class="pt-5 news-header text-center d-flex flex-column justify-content-center mt-sm-1-custom"
      >
        <h4>{{ $t("website.news.title") }}</h4>
      </div>
      <News />
    </b-container>
  </div>
</template>

<script>
import News from "../components/News.vue";

export default {
  name: "news",
  components: {
    News,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      title: "Powering digital payments and identity verification",
      description:
        "Accept online payments with card, FPX and e-Wallet in less than a minute using Tekkis no-code solutions. No Tech team or website needed. Tekkis also offers API integrations for both eKYC and online payments.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Accept online payments with card, FPX and e-Wallet in less than a minute using Tekkis no-code solutions. No Tech team or website needed. Tekkis also offers API integrations for both eKYC and online payments.";
    },
  },
};
</script>

<style lang="scss" scoped>
.news-header {
  height: 185px;
  font-size: 30px;
  font-weight: 400;
  color: #4f4f4f;
}

@media (max-width: 767.98px) {
  .mt-sm-1-custom {
    margin-top: 1rem !important;
  }
}
</style>
